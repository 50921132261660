
<template>
  <div v-if="item.children">
    <template v-if="item.children.length == 0">
      <el-menu-item :index="item.requestUrl">
        <i :class="`iconfont ${item.iconName}`"></i>
        {{item.permissionName}}
      </el-menu-item>
    </template>

    <el-submenu v-else :index="item.requestUrl">
      <template slot="title" >
        <i :class="`iconfont ${item.iconName}`"></i>
        {{item.permissionName}}
      </template>

      <template v-for="child in item.children">
        <sidebar-item
          v-if="child.children&&child.children.length>0"
          :item="child"
          :key="child.id"/>
        <el-menu-item class="elmenuitemstyle" v-else :key="child.id" :index="child.requestUrl">
          <i :class="`iconfont ${child.iconName}`"></i>
          {{child.permissionName}}
        </el-menu-item>
      </template>
    </el-submenu>
  </div>
</template>

<script>
export default {
  name: 'SidebarItem',
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      iconsList: {
        1: 'iconfont icon-zhuye', //主页
        3: 'iconfont icon-quanxian', // 权限管理
        25: 'iconfont icon-dingdanguanli', //订单管理
        48: 'iconfont icon-ziyuan143', //基础数据管理
        322: 'iconfont icon-xitongfuwu', //测试环境"系统管理"icon图标

        4: 'iconfont icon-yonghu1', //用户管理
        9: 'iconfont icon-role', //角色管理
        15: 'iconfont icon-ziyuanguanli', //资源管理
        20: 'iconfont icon-duoren',//用户组管理

        26: 'iconfont icon-dingdan',//用户订单
        32: 'iconfont icon-dingdanshenhe',//订单审核
        36: 'iconfont icon-ziliaoshenhe',//资料审核
        40: 'iconfont icon-sanwei',//三维重建
        44: 'iconfont icon-jieguoshenhe_huaban1',//重建结果审核
        338:'iconfont  icon-baobiao', //测试环境"报表导出"icon图标

        49: 'iconfont icon-navicon-pgtcgl',//套餐管理
        283: 'iconfont iconfont icon-yiyuan', //医院管理
        297: 'iconfont iconfont icon-fabu', //发布管理
        301: 'iconfont iconfont icon-shanghu', //商户管理
        333:'iconfont  icon-youhui', //测试环境"优惠码管理"icon图标

        328: 'iconfont icon-xitongjiankong', //测试环境"院内系统监控"icon图标
        341:'iconfont icon-xitong-caozuorizhi',//测试环境"操作日志"icon图标

        318: 'iconfont iconfont icon-xitongfuwu', //生产环境"系统管理" icon图标
        319: 'iconfont icon-xitongjiankong', //生产环境"院内系统监控"icon图标
      },
    }
  },
}
</script>
<style lang='less' scoped>
.elmenuitemstyle {
  background-color: #1f2d3d !important;
}
.iconfont {
  margin-right:10px;
  font-size: .9375rem;
}
</style>