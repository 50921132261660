<template>
    <!-- <width-switch :width="500"> -->
        <!-- <div id="home" slot='a'> -->
        <div id="home">
            <el-container>
                <el-aside :width="isCollapse ? '0px' : '200px'" style="overflow-x:hidden;">
                    <div class='aside'>
                        <div class='asidetop'>
                            <!-- <q-avatar size='25px'>
                                <img src="@/assets/avatar.png">
                            </q-avatar> -->
                            <div class='toolbartitle'>订单处理系统</div>
                        </div>
                        <div class='menubox'>
                            <el-menu
                                class="el-menu-vertical-demo"
                                background-color="#304156"
                                text-color="#fff"
                                active-text-color="#409eff"
                                router
                                :collapse="isCollapse"
                                :default-active="$route.path"   
                                :collapse-transition="true"
                                :unique-opened="true"
                                > 
                                    <!-- <el-submenu v-for="item in munsList" :key="item.id" :index="item.requestUrl">
                                        <template slot="title"> 
                                            <span>{{item.permissionName}}</span>
                                        </template> 
                                        <template v-if="item.children.length > 0">
                                            <el-menu-item v-for="item2 in item.children" :key="item2.id" :index="item2.requestUrl">
                                                <template slot="title">
                                                    <i class="el-icon-s-grid"></i>
                                                    <span>{{item2.permissionName}}</span>
                                                </template>
                                            </el-menu-item>
                                        </template>
                                        
                                    </el-submenu> -->
                                    <sidebar-item v-for="menu in munsList" :key="menu.id" :item="menu" />
                            </el-menu>
                        </div>
                    </div>
                </el-aside>
                <el-container>
                    <el-header class="headerall">
                        <q-toolbar>
                            <q-avatar>
                                <q-btn dense flat round icon="menu" @click="isCollapse = !isCollapse" />
                            </q-avatar>
                            <q-toolbar-title> </q-toolbar-title>
                                <!-- 站内信息提示 -->
                                <el-badge :value="noReadNum" :hidden='noReadNum == 0' class="item">
                                    <!-- <el-button type="primary" icon="el-icon-edit" circle></el-button> -->
                                    <el-popover
                                        placement="bottom"
                                        width="400"
                                        trigger="click"
                                        popper-class='fetchNoReadNum'
                                        @show='popoverShow'
                                        @hide='popoverHide'
                                    >
                                        <div>
                                            <div class="tabbox">
                                                <el-tabs v-model="stationInformation" @tab-click="handleClick">
                                                    <!-- 未读消息 -->
                                                    <el-tab-pane label="未读通知" name="unRead">
                                                        <div class='maxh'  v-if='noReadMessagesList.length'>
                                                            <div class='read' :id='item.logId' v-for="(item) in noReadMessagesList" :key="item.logId"
                                                                @mouseenter='mouseEnter(item)' @mouseleave='mouseLeave(item)' @click="readClick(item)"
                                                            >
                                                                <div class="tit">
                                                                    <div class="name">{{item.messageUser}}</div>
                                                                    <div class="del">
                                                                        <el-button class="delbtn" :id='"btn" + item.logId' icon="el-icon-close" circle size="mini"
                                                                            @click="delmessage(item)"
                                                                        >
                                                                        </el-button>
                                                                    </div>
                                                                </div>
                                                                <div class="main">
                                                                    {{item.message}}
                                                                </div>
                                                                <div class="time">
                                                                    {{item.createTime}}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class='nomessage' v-else>
                                                            暂无通知
                                                        </div>
                                                    </el-tab-pane>
                                                    <!-- 已读消息 -->
                                                    <el-tab-pane label="已读通知" name="Read">
                                                        <div class='maxh' v-if='readMessagesList.length'>
                                                            <div class='read' :id='item.logId' v-for="(item) in readMessagesList" :key="item.logId"
                                                                @mouseenter='mouseEnter(item)' @mouseleave='mouseLeave(item)'
                                                            >
                                                                <div class="tit">
                                                                    <div class="name">{{item.messageUser}}</div>
                                                                    <div class="del">
                                                                        <el-button class="delbtn" :id='"btn" + item.logId' icon="el-icon-close" circle size="mini"
                                                                            @click="delmessage(item)"
                                                                        >
                                                                        </el-button>
                                                                    </div>
                                                                </div>
                                                                <div class="main">
                                                                    {{item.message}}
                                                                </div>
                                                                <div class="time">
                                                                    {{item.createTime}}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class='nomessage' v-else>
                                                            暂无通知
                                                        </div>
                                                    </el-tab-pane>
                                                </el-tabs>

                                                <div class="loginOut">
                                                    <!-- <el-popover
                                                        placement="bottom"
                                                        width="200"
                                                        trigger="hover"
                                                    >
                                                        <el-button slot="reference" icon="el-icon-s-tools" circle size="mini"></el-button>
                                                    </el-popover> -->
                                                    <el-dropdown>
                                                        <el-button icon="el-icon-s-tools" circle size="mini"></el-button>
                                                        <el-dropdown-menu slot="dropdown">
                                                            <el-dropdown-item @click.native='readAll'>全部已读</el-dropdown-item>
                                                            <el-dropdown-item  @click.native='delAll'>全部删除</el-dropdown-item>
                                                        </el-dropdown-menu>
                                                    </el-dropdown>
                                                </div>
                                            </div>
                                            <div v-if='maxNum != 0 && maxNum != 1' class='pagination'>
                                                <q-pagination
                                                    @input ='paginationChange'
                                                    v-model="current"
                                                    :max="maxNum"
                                                    :max-pages="6"
                                                    :boundary-numbers="false"
                                                />
                                            </div>
                                        </div>
                                        <!-- 站内信息按钮 -->
                                        <el-button
                                            style='border: none;font-size: 16px;'
                                            slot="reference"
                                            size="small"
                                            icon="el-icon-message-solid" 
                                            circle
                                        >
                                        </el-button>
                                    </el-popover>
                                </el-badge>
                                 <!-- 用户头像 -->
                                <!-- <q-avatar size="30px" class="head">
                                    <img  src="../../public/ES6T79JKXBMT]3BKJ3R)Q[R.jpg">
                                </q-avatar> -->
                                <!-- 用户信息按钮  icon="person" :label='userName' icon-right="iconfont icon-xiangxia"-->
                                <q-btn class="userbtn" flat icon-right="iconfont icon-xiangxia">
                                    <!-- 用户头像 -->
                                    <q-avatar size="30px" class="head">
                                        <img id='avatarHeader' :src="avatar">
                                    </q-avatar>
                                    <q-menu :offset="[40, 10]" anchor="bottom middle" self="top middle">
                                        <div class="no-wrap q-pa-md personal">
                                            <div class="column items-center">
                                                <q-list class="personalbtn" bordered separator dense>
                                                    <q-item clickable v-ripple>
                                                        <q-item-section class='itemsection'>{{userNickName}}</q-item-section>
                                                    </q-item>
                                                </q-list>
                                                <q-list class="personalbtn" bordered separator dense >
                                                    <q-item clickable v-ripple v-close-popup>
                                                        <q-item-section class='itemsection' @click='setup'>设置</q-item-section>
                                                    </q-item>
                                                </q-list>
                                                <q-list class="personalbtn" bordered separator dense>
                                                    <q-item clickable v-ripple>
                                                        <q-item-section class='itemsection' @click='logout'>退出登录</q-item-section>
                                                    </q-item>
                                                </q-list>
                                                
                                            </div>
                                        </div>
                                    </q-menu>
                                </q-btn>
                        </q-toolbar>
                    </el-header>
                    <el-main style="height:0;flex-grow:1;">
                        <router-view></router-view>
                    </el-main>
                </el-container>
            </el-container>
            
        </div>
        <!-- <div id="home" slot='b'>
            <el-main>
                <router-view></router-view>
            </el-main>
        </div> -->
    <!-- </width-switch> -->
  
</template>

<script>
import { mapState } from 'vuex' //vuex获取数据

import WidthSwitch from '@/components/Widthwatch/WidthSwitch.vue'
import jwt from 'jsonwebtoken'
import SidebarItem from '@/components/ElementUI/SidebarItem.vue'
import { Logout,GetMenu,Editpassword
,fetchNoReadNum,fetchNoReadMessages,fetchReadMessages
,messageDelete,deleteAllMessage,markAllMessageWithRead,
markMessageRead,
} from '@/api/Login.js'
import { getItem, removeItem } from '../utils/storage.js';
import { dict } from '@/api/components/dict.js'; // 数据词典接口
import { Permissions } from '@/api/Allpermissions.js'; // 获取权限
import { getUserAvatar } from '@/api/components/getAvatar.js'; // 获取头像
import {logo} from "@/assets/logo.js";    
import { notifys } from '@/api/components/Notify.js'

export default {
    data() {
        return {

            isCollapse: false,
            right: false,
            munsList:[],
            userName: '',  //登录的者用户名

            dict:{}, // 数据词典

            current:1,// 分页器绑定默认页码
            timeInterval: null, //打包定时器
            timelist:[],

            noReadNum:0, //未读消息数量
            maxNum:0,
            //未读通知
            noReadMessagesList:[],
            noReadMessages:{
                page:1, //默认页码1
                pageSize:5, //默认每页5条
                total:0, //总数
            },
            menuValue: false,
            //已读通知
            readMessagesList:[],
            readMessages:{
                page:1, //默认页码1
                pageSize:5, //默认每页5条
                total:0, //总数
            },
            stationInformation:'unRead', //站内信息

            avatar: sessionStorage.getItem('avatar') || '../assets/头像.png'
        };
    },
    created(){
        this.getMunsList()
        this.getdict() // 获取数据词典
        this.getPermissions() // 获取权限词典
        this.getunReadNum() //获取未读通知数量
    },
    mounted(){
        this.mountedInterval()
        this.getAvatar() // 获取头像信息
    },
    components:{
        WidthSwitch,
        SidebarItem
    },
    computed:{    
        ...mapState([
        'userNickName', //用户昵称
        ]),
    },
    beforeDestroy(){
        console.log('退出了');
        this.intervalEnd()
    },
    destroyed(){
        console.log('退出了');
        this.intervalEnd()
    },
    watch:{
    },
    methods: {
        setup(){
            this.menuValue = false
            console.log(this.$route);
            if(this.$route.path != "/PersonalCenter"){
                this.$router.push("/PersonalCenter")
            }
            this.menuValue = false
        },
        // 获取头像
        async getAvatar(){
            const token = getItem('token')
            // console.log(token);
            const imageUrl =await getUserAvatar(token)

            let avatarHeader = document.getElementById('avatarHeader')

            avatarHeader.src = imageUrl
        },
        // 获取菜单
        async getMunsList(){
            try {
                const token = getItem('token')
                const {data:res} = await GetMenu(token)
                console.log('侧边栏内容',res);
                // if(res.code===20000){
                console.log('token中的内容',jwt.decode(token));
                let userName=jwt.decode(token).nickName.trim()==''? jwt.decode(token).sub : jwt.decode(token).nickName;
                this.$store.commit('getuserNickName',userName)
                this.munsList=res.data.children
            }catch(e){
                console.log(e);
            }
        },
        // 退出账号
        logout(){
            this.$q.dialog({
            title: '退出',
            message: '你确定要退出登录吗?',
            ok: { label: '确定',flat: true,},
            cancel: { label: '取消',flat: true},
            }).onOk(async() => {
                try{
                    const token = getItem('token')
                    const { data:res } = await Logout(token)
                    console.log(res);
                    if(res.code===20000){
                        this.intervalEnd()
                        notifys('positive',res.message)
                        removeItem('token')
                        this.dict = []
                        this.$store.commit('getdashBoardType',[])
                        this.$store.commit('getorderType',[])
                        this.$store.commit('getuserType',[])
                        this.$store.commit('getinformationStatus',[])
                        this.$store.commit('getorderStatus',[])
                        this.$store.commit('getorderCheckStatus',[])
                        this.$store.commit('getpayChannel',[])
                        this.$store.commit('getpayStatus',[])
                        this.$store.commit('getinformationType',[])
                        this.$store.commit('getreconStatus',[])
                        this.$store.commit('getresultStatus',[])
                        this.$store.commit('getpermissionType',[]) //资源管理类型


                        this.$store.commit('apkType',[])
                        this.$store.commit('osType',[])
                        this.$store.commit('publishStatus',[])
                        this.$store.commit('publishType',[])
                        this.$store.commit('discountStatus',[])
                        this.$store.commit('orderUrgent',[])

                        this.$store.commit('businessStatus',[])
                        this.$store.commit('businessType',[])
                        this.$store.commit('moduleName',[])

                        this.$router.push('/login')
                    }else{
                        notifys('negative',res.message)
                    }
                }catch (e){
                    console.log(e);
                }
            })
        },

        async getPermissions(){
            const { data: res } = await Permissions()
            console.log('权限字典',res);
            const permissions = res.data.permissions
            this.$store.commit('getPermissionslist',permissions)
        },
        // 获取数据词典
        async getdict(){
            const { data:res } = await dict()
            this.dict = res.data.dict
            //   console.log(this.dict);
            console.log('下拉框数据词典',res);
            this.$store.commit('getdashBoardType',this.dict.dashBoardType)
            this.$store.commit('getorderType',this.dict.orderType)
            this.$store.commit('getuserType',this.dict.userType)
            this.$store.commit('getinformationStatus',this.dict.informationStatus)
            this.$store.commit('getorderStatus',this.dict.orderStatus)
            this.$store.commit('getorderCheckStatus',this.dict.orderCheckStatus)
            this.$store.commit('getpayChannel',this.dict.payChannel)
            this.$store.commit('getpayStatus',this.dict.payStatus)
            this.$store.commit('getinformationType',this.dict.informationType)
            this.$store.commit('getreconStatus',this.dict.reconStatus)
            this.$store.commit('getresultStatus',this.dict.resultStatus)
            this.$store.commit('getpermissionType',this.dict.permissionType) //资源管理类型

            this.$store.commit('getapkType',this.dict.apkType)
            this.$store.commit('getpublishStatus',this.dict.publishStatus)
            this.$store.commit('getpublishType',this.dict.publishType)
            this.$store.commit('getosType',this.dict.osType)
            this.$store.commit('getdiscountStatus',this.dict.discountStatus)
            this.$store.commit('getorderUrgent',this.dict.orderUrgent)

            this.$store.commit('getbusinessStatus',this.dict.businessStatus)
            this.$store.commit('getbusinessType',this.dict.businessType)
            this.$store.commit('getmoduleName',this.dict.moduleName)
        },

        //站内切换
        handleClick(){
            console.log(this.stationInformation);
            if(this.stationInformation == 'unRead'){
                // 重置未读页码都为1
                this.noReadMessages.page = 1
                this.current = 1
                // 获取未读消息
                this.getUnRead()
            }else{
                // 重置已读页码都为1
                this.readMessages.page = 1
                this.current = 1
                // 获取已读通知
            this.getRead() 
            }
        },
        // 站内信息popover打开时
        popoverShow(){
            // 获取未读消息
            this.getUnRead()
        },
        // 站内信息popover关闭时
        popoverHide(){
            // 将tab调整到未读通知区域
            this.stationInformation = 'unRead'
            // 重置已读未读页码都为1
            this.noReadMessages.page = 1
            this.readMessages.page = 1
        },
        // 获取未读通知数量
        async getunReadNum(){
            const { data:res } = await fetchNoReadNum()
            if(res.code===20000){
                this.noReadNum  = res.data.num * 1
            }
        },
        // 循环调用未读数量
        mountedInterval(){
            this.intervalEnd()
            this.timeInterval = setInterval(async()=>{
                this.timelist.push(this.timeInterval)
                const { data:res } = await fetchNoReadNum()
                if(res.code===20000){
                    this.noReadNum  = res.data.num * 1
                }
            },5000)
            this.timelist.push(this.timeInterval)
        },
        //清除定时器
        intervalEnd(){
            console.log('timeInterval',this.timeInterval);
            console.log('timelist',this.timelist);
            if (this.timeInterval) {
                console.log("结束了");
                this.timelist.forEach((item, index) => {
                    window.clearInterval(item);
                })
                this.timelist = [];
                this.timeInterval = null;
            }
        },
        // 获取已读通知
        async getRead(){
            const { data:res } = await fetchReadMessages({
                ...this.readMessages
            })
            if(res.code===20000){
                this.readMessagesList = res.data.messages
                // 获取总条数
                this.readMessages.total = res.data.totals * 1
                // 储存最大页码数
                this.maxNum = res.data.pages * 1
            }
            // console.log(res);
        },
        // 获取未读通知
        async getUnRead(){
            const { data:res } = await fetchNoReadMessages({
                ...this.noReadMessages
            })
            if(res.code===20000){
                this.noReadMessagesList = res.data.messages
                // 获取总条数
                this.noReadMessages.total = res.data.totals * 1
                // 储存最大页码数
                this.maxNum = res.data.pages * 1
            }
            // console.log(res);
        },
        // 站内通知页码跳转
        paginationChange(num){
            // console.log(num);
            if(this.stationInformation == 'unRead'){
                this.noReadMessages.page = num
                // 获取未读消息
                this.getUnRead()
            }else{
                this.readMessages.page = num
                // 获取已读通知
                this.getRead() 
            }
        },
        // 删除单一通知
        async delmessage(v){
            const { data: res } = await messageDelete({
                logId: v.logId
            })
            if(res.code===20000){
                // 如果当前id是要删除的id
                if(this.stationInformation == 'unRead'){
                    // 判断规则:（当前页码-1）*页容量+要被删除项的索引+1===总条数 && 判断被删项索引是否为0 && 当前不是第一页
                    this.noReadMessagesList.forEach((item, index) => {
                        if (item.logId === v.logId) {
                            if (
                                (this.noReadMessages.page - 1) * this.noReadMessages.pageSize + index + 1 == this.noReadMessages.total
                                && index == 0
                                && this.noReadMessages.page > 0
                            ) {
                                // 如果是就将页码回到上一页
                                this.noReadMessages.page--;
                                this.current = this.noReadMessages.page
                            }
                        }
                    })
                    // 获取未读消息
                    this.getUnRead()
                    // 获取未读数量
                    this.getunReadNum()
                }else{
                    this.readMessagesList.forEach((item, index) => {
                        if (item.logId === v.logId) {
                            if (
                                (this.readMessages.page - 1) * this.readMessages.pageSize + index + 1 == this.readMessages.total
                                && index == 0
                                && this.readMessages.page > 0
                            ) {
                                // 如果是就将页码回到上一页
                                this.readMessages.page--;
                                this.current = this.readMessages.page
                            }
                        }
                    })
                    // 获取已读通知
                    this.getRead() 
                }
            }
        },
        //全部删除
        delAll(){
            console.log('全部删除');
            this.$q.dialog({
                title: '全部删除',
                message: `你是否要删除所有通知`,
                ok: { label: '确定',color: 'red'},
                cancel: { label: '取消',flat: true},
            }).onOk(async() => {
                try{
                    const { data:res } = await deleteAllMessage()
                    if(res.code===20000){
                        this.getunReadNum()
                        // 重置已读未读页码都为1
                        this.noReadMessages.page = 1
                        this.readMessages.page = 1
                        this.getUnRead()
                        this.getRead()
                    }
                }catch (e){
                    console.log(e);
                }
            })
        },
        //全部已读
        readAll(){
            console.log('全部已读');
            this.$q.dialog({
                title: '全部已读',
                message: `你是否要将所有未读通知改为已读状态`,
                ok: { label: '确定',color: 'red'},
                cancel: { label: '取消',flat: true},
            }).onOk(async() => {
                try{
                    const { data:res } = await markAllMessageWithRead()
                    if(res.code===20000){
                        this.getunReadNum()
                        // 重置已读未读页码都为1
                        this.noReadMessages.page = 1
                        this.readMessages.page = 1
                        this.getUnRead()
                        this.getRead()
                    }
                }catch (e){
                    console.log(e);
                }
            })
        },
        // 鼠标进入
        mouseEnter(v){
            // console.log('进入'); //#f9f9f9
            document.getElementById(`btn${v.logId}`).style.display = 'block'
            document.getElementById(`${v.logId}`).style.background = '#f9f9f9'
        },
        // 鼠标移开
        mouseLeave(v){
            // console.log('移出');
            document.getElementById(`btn${v.logId}`).style.display = 'none'
            document.getElementById(`${v.logId}`).style.background = '#fff'
        },
        // 点击未读消息触发
        async readClick(v){
            console.log('点击',v);
            const { data: res } = await markMessageRead({
                logId: v.logId
            })
            if(res.code===20000){
                // 判断规则:（当前页码-1）*页容量+要被删除项的索引+1===总条数 && 判断被删项索引是否为0 && 当前不是第一页
                this.noReadMessagesList.forEach((item, index) => {
                    if (item.logId === v.logId) {
                        if (
                            (this.noReadMessages.page - 1) * this.noReadMessages.pageSize + index + 1 == this.noReadMessages.total
                            && index == 0
                            && this.noReadMessages.page > 0
                        ) {
                            // 如果是就将页码回到上一页
                            this.noReadMessages.page--;
                            this.current = this.noReadMessages.page
                        }
                    }
                })
                // 获取未读消息
                this.getUnRead()
                // 获取未读数量
                this.getunReadNum()
            }
        },
    },
};
</script>

<style lang="less" scoped>

#home{
    height: 100vh;
    .aside {
        background: #304156; 
        height: 100%;
        // overflow: hidden;
        .asidetop{
            display: flex;
            align-items:center;
            text-align: left;
            height: 60px;
            color: #fff;
            justify-content: left;
            // padding-right: 1.5rem;
            padding:0 1.5rem;
            .toolbartitle{
                // padding: 0 .625rem;
                text-align: left;
                font-size: .9375rem;
                font-weight:bold;
            }
        }
        .menubox{
            height: calc(100% - 60px);
            overflow: auto;
            overflow-x:hidden;
            .el-menu {
                border-right: none;
            } 
        }
        
    
    }
    .el-main {
      background: #fff;
      overflow: auto !important;
      padding-top: 0 !important;
    }
    .editcard{
        width: 80%;
        width: 13.75rem;
        width: 21.875rem;
    }
}
.el-container{
    height: 100%;
}
.iconstyle{
    min-width: 1.125rem;
    padding-right: .625rem;
}
.navigationbar{
    max-width: 13.75rem;
}
.personal{
    padding: 0;
}
.personalbtn{
    width: 100%;
    border: none;
    text-align: center;
    .itemsection{
        text-align: left;
    }
}

.q-toolbar{
    padding: 0 12px;
    // padding-right: 1.875rem;
}
.headerall{
    background-color: #fff;
    padding-top: 3px;
    /deep/.on-right{
        margin: 0;
    }
    /deep/.q-btn__wrapper {
        padding: 4px 5px;
    }
    .userbtn{
        margin-left: 25px;
    }

}

.read:hover{
    cursor:pointer;
}

.q-pa-md {
    padding-top: 0;
}

</style>
